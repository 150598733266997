import React from "react";

import { track } from "../../utils/googleAnalytics";

function ActionJoinNetwork() {
  const handleClick = () => {
    track({
      category: "link",
      action: "Click",
      label: "intro panel to reserve spot",
    });
  };

  return (
    <a
      className="bg-white flex justify-center items-center text-center rounded w-full px-12 lg:px-20 py-4 text-black text-2xl"
      href="https://www.really.com/become-a-host"
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      <span className="mr-4">become a host</span>
    </a>
  );
}

export default ActionJoinNetwork;

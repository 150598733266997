import React from "react";
import { Link } from "react-router-dom";

/**
 * A functional component that renders a list element.
 * If the 'reserved' prop is true, it renders a static element.
 * Otherwise, it renders a link to the reservation page.
 * 
 * @param {Props} props - The props that define whether the list element is reserved or not.
 * @property {boolean} reserved - A boolean prop that indicates whether the list element is reserved or not.

 * @returns {JSX.Element} The rendered list element.

 * @example
 * <RadioListElement reserved= />
 */
function RadioListElement(props) {
  const { reserved } = props;
  if (reserved) {
    return (
      <div className="pl-4 py-5 grid grid-cols-[40px_1fr_40px] button-radio-disabled w-full">
        <div className="col-start-1 pt-[0.25rem]">
          <svg
            id="connectivityIcon"
            width="24"
            height="24"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.3641 5.63599C25.8788 9.15071 25.8788 14.8492 22.3641 18.3639M9.63613 18.3639C6.12141 14.8492 6.12141 9.15071 9.63613 5.63599M5.39315 22.6066C-0.46471 16.7488 -0.46471 7.2513 5.39315 1.39343M26.607 1.39343C32.4649 7.2513 32.4649 16.7488 26.607 22.6066M16.0001 15C17.6569 15 19.0001 13.6569 19.0001 12C19.0001 10.3431 17.6569 9 16.0001 9C14.3432 9 13.0001 10.3431 13.0001 12C13.0001 13.6569 14.3432 15 16.0001 15ZM16.0001 15V28.5"
              className="stroke-slate-600"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h3 className="text-xl font-light italic justify-start truncate text-slate-600">
          Spot Reserved
        </h3>
      </div>
    );
  }

  return (
    <Link
      to="https://www.really.com/become-a-host"
      target="_blank"
      className="pl-4 py-5 grid grid-cols-[40px_1fr_40px] button-radio-disabled w-full"
    >
      <div className="col-start-1 pt-[0.25rem]">
        <svg
          id="connectivityIcon"
          width="24"
          height="25"
          viewBox="0 0 32 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.3641 5.63599C25.8788 9.15071 25.8788 14.8492 22.3641 18.3639M9.63613 18.3639C6.12141 14.8492 6.12141 9.15071 9.63613 5.63599M5.39315 22.6066C-0.46471 16.7488 -0.46471 7.2513 5.39315 1.39343M26.607 1.39343C32.4649 7.2513 32.4649 16.7488 26.607 22.6066M16.0001 15C17.6569 15 19.0001 13.6569 19.0001 12C19.0001 10.3431 17.6569 9 16.0001 9C14.3432 9 13.0001 10.3431 13.0001 12C13.0001 13.6569 14.3432 15 16.0001 15ZM16.0001 15V28.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <h3 className="text-xl font-medium justify-start truncate ml-0">
        Reserve this spot
      </h3>
      <div className="pt-3 col-start-3">
        <svg
          id="forwardIcon"
          width="8"
          height="16"
          viewBox="0 0 10 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 17L9 9L1 1"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Link>
  );
}

export default RadioListElement;

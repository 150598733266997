import React from "react";
import WaitlistTimer from "../../icons/WaitlistTimer";

export const MessageHexAvailability = (props) => {
  const { numberSpotsAvailable } = props;

  if (numberSpotsAvailable <= 0)
    return (
      <>
        <div className="flex flex-col items-center text-xl">
          <p className="label-yellow-background font-semibold mb-2">
            This hex is currently full.
          </p>
        </div>
        <a
          href="https://www.really.com/become-a-host"
          target="_blank"
          rel="noreferrer"
          className="button-purple-shadow text-black font-bold text-3xl mt-1 py-6 w-full"
        >
          join the waitlist{" "}
          <span className="ml-2 mt-1">
            <WaitlistTimer />
          </span>
        </a>
      </>
    );
  if (numberSpotsAvailable === 1)
    return (
      <div className="flex flex-col items-center text-xl">
        <p className="label-yellow-background font-black">
          Only 1 spot left in this area.
        </p>
        <p className="label-yellow-background font-medium">
          Reserve now and start earning!
        </p>
      </div>
    );
  if (numberSpotsAvailable > 1)
    return (
      <>
        <div className="flex flex-col items-center text-xl">
          <p className="label-yellow-background font-black ">
            {numberSpotsAvailable} spots left in this area.
          </p>
          <p className="label-yellow-background font-medium">
            Reserve now and start earning!
          </p>
        </div>
      </>
    );

  throw new Error(
    "MessageHexAvailability: Invalid value for numberSpotsAvailable"
  );
};
